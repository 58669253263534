import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

export default function Header({ siteTitle }) {
  const [open, setOpen] = React.useState(
    'flex-grow hidden w-full lg:flex lg:items-center lg:justify-end lg:w-auto'
  )

  const setMobileOpen = () =>
    open ===
    'flex-grow hidden w-full lg:flex lg:items-center lg:justify-end lg:w-auto'
      ? setOpen(
          'flex-grow w-full lg:flex lg:items-center lg:justify-end lg:w-auto'
        )
      : setOpen(
          'flex-grow hidden w-full lg:flex lg:items-center lg:justify-end lg:w-auto'
        )

  return (
    <header
      className="bg-opacity-60 bg-white border-b fixed inset-x-0 top-0 w-full z-50"
      data-pgc-define=".header"
      data-pgc-define-name="Header"
      data-pgc="header"
    >
      <nav className="container flex flex-row flex-wrap items-center justify-between mx-auto px-4 py-2 lg:py-0">
        <Link
          to="/"
          className="font-bold hover:text-red-600 mr-3 text-gray-900 text-xl"
        >
          Carson Bluffs Self Storage
        </Link>
        <button
          className="bg-red-600 hover:bg-red-700 hover:text-white px-3 py-2 rounded text-white lg:hidden"
          onClick={setMobileOpen}
          data-name="nav-toggler"
          data-pg-ia='{"l":[{"name":"NabMenuToggler","trg":"click","a":{"l":[{"t":"^nav|[data-name=nav-menu]","l":[{"t":"set","p":0,"d":0,"l":{"className.remove":"hidden"}}]},{"t":"#gt# span:nth-of-type(1)","l":[{"t":"tween","p":0,"d":0.2,"l":{"rotationZ":45,"yPercent":300}}]},{"t":"#gt# span:nth-of-type(2)","l":[{"t":"tween","p":0,"d":0.2,"l":{"autoAlpha":0}}]},{"t":"#gt# span:nth-of-type(3)","l":[{"t":"tween","p":0,"d":0.2,"l":{"rotationZ":-45,"yPercent":-300}}]}]},"pdef":"true","trev":"true"}]}'
        >
          <span className="block border-b-2 border-current my-1 w-6"></span>
          <span className="block border-b-2 border-current my-1 w-6"></span>
          <span className="block border-b-2 border-current my-1 w-6"></span>
        </button>
        <div data-name="nav-menu" className={open}>
          <div className="flex flex-col lg:flex-row">
            <Link
              to="/"
              className="hover:text-red-600 px-0 py-2 text-gray-900 lg:px-4 lg:py-6"
            >
              Home
            </Link>
            <Link
              to="/about-us"
              className="hover:text-red-600 px-0 py-2 text-gray-900 lg:px-4 lg:py-6"
            >
              About Us
            </Link>
            {/* <Link
              to="/blog"
              className="hover:text-red-600 px-0 py-2 text-gray-900 lg:px-4 lg:py-6"
            >
              Blog
            </Link> */}
            <Link
              className="hover:text-red-600 px-0 py-2 text-gray-900 lg:px-4 lg:py-6"
              to="/contact"
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}
